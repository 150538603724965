<template>
  <div class="chat-bubble">
    <div class="message-content" :class="bubbleStyle" v-if="props.showPopover">
      <van-popover
        trigger="click"
        theme="dark"
        placement="top"
        :actions="actions"
        actions-direction="horizontal"
        @select="onSelect"
        @open="open"
      >
        <template #reference> <slot></slot> </template>
      </van-popover>
    </div>
    <div class="message-content" :class="bubbleStyle" v-else><slot></slot></div>
  </div>
</template>
<script setup>
import { copyDomText } from "@/untils/index";
import { computed, onMounted, ref } from "vue";
const showPopover = ref(false);
const actions = ref([{ text: "复制" }]);
const props = defineProps({
  isMine: {
    type: Boolean,
  },
  message: {
    type: [Object,String],
    default: [{},''],
  },
  showPopover: {
    type: Boolean,
    default: false,
  },
});
function open() {
  if (props.showPopover) {
    showPopover.value = true;
  } else {
    showPopover.value = false;
  }
}
const bubbleStyle = computed(() => {
  let classString = "";
  if (props.isMine) {
    classString += "message-send";
  } else {
    classString += "message-received";
  }

  return classString;
});
function onSelect(v) {
  //console.log(props.message);
  copyDomText(props.message);
}
</script>

<style scoped>
.message-send {
  margin-right: 2.66vw;
  color: #fff;
  text-align: right;
  border-radius: 4px;
}
.message-received {
  margin-left: 2.66vw;
  color: #222222;
  text-align: left;
  border-radius: 4px;
}
</style>
