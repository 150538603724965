<template>
  <message-bubble :isMine="isMine" :message="message">
    <img :src="props.imgUrl" alt="" @click.stop="visible = true" class="img" />
    <van-overlay :show="visible" @click.stop="visible = false">
      <img :src="props.imgUrl" alt="" class="overlay_img" />
    </van-overlay>
  </message-bubble>
</template>
<script setup>
import { ref } from "vue";
import MessageBubble from "./messageBubble";
const props = defineProps({
  isMine: Boolean,
  imgUrl: String,
});
const visible = ref(false);
</script>

<style scoped>
.img {
  width: 42.66vw;
  height: 42.66vw;
  display: block;
  border-radius: 4px;
  object-fit: cover;
  display: block;
}
.van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay_img {
  width: 100%;
}
</style>
