<template>
  <div class="message-wrapper">
    <div class="date" v-if="props.index % 7 == 0">{{ imTime(props.message.time) }}</div>
    <div class="systemMsg">
      <GroupTip
        :item="props.message"
        v-if="
          props.message.type == TencentCloudChat.TYPES.MSG_GRP_TIP ||
          props.message.type == TencentCloudChat.TYPES.MSG_GRP_SYS_NOTICE ||
          props.message.isRevoked
        "
      />
      <RedPacketTips
        v-if="props.message.type == 'TIMCustomElem' && _data.action == 'IMChatRecPocketGain'"
        :item="props.message"
      />
      <GroupTipsCustom
        v-if="props.message.type == 'TIMCustomElem' && _data.businessID == 'group_create'"
        :item="_data"
      />
    </div>
    <div
      class="item"
      :class="messagePosition"
      v-if="
        (props.message.type == 'TIMTextElem' ||
          props.message.type == 'TIMImageElem' ||
          props.message.type == 'TIMVideoFileElem' ||
          (props.message.type == 'TIMCustomElem' && _data.action == 'IMChatCustomEmoji') ||
          (props.message.type == 'TIMCustomElem' && _data.action == 'IMChatRedPocket')) &&
        !props.message.isRevoked &&
        !props.message.isDeleted
      "
    >
      <div class="col-1 touch" @touchstart="gotouchstart">
        <!-- 头像 -->
        <!-- 对方头像 -->
        <img :src="filesCdnDomain + props.message.avatar" v-if="props.message.avatar && !isMine" />
        <img
          src="@/assets/img/icon/avart.png"
          v-if="!props.message.avatar && !isMine"
          class="touch"
        />
        <!-- 自己头像  -->

        <img :src="filesCdnDomain + props.message.avatar" v-if="props.message.avatar && isMine" />
        <img
          src="@/assets/img/icon/avart.png"
          v-if="!props.message.avatar && isMine"
          class="touch"
        />
      </div>
      <div class="col_2">
        <div class="content-wrapper">
          <div class="person_name" v-if="!isMine">
            <!-- 群聊时，若该好友有备注名称显示为： 备注名（昵称） -->
            <span v-if="imChatType == 'GROUP'">{{ props.message.nick }}</span>
          </div>
          <MessageImg
            :isMine="isMine"
            :imgUrl="
              props.message.payload.imageInfoArray[props.message.payload.imageInfoArray.length - 1]
                .imageUrl
            "
            v-if="props.message.type == TencentCloudChat.TYPES.MSG_IMAGE"
          />
          <MessageRedEnvelope
            v-if="props.message.type == 'TIMCustomElem' && _data.action == 'IMChatRedPocket'"
            :isMine="isMine"
            :message="props.message"
          />
          <MsgVideo
            v-if="props.message.type == TencentCloudChat.TYPES.MSG_VIDEO"
            :item="props.message.payload"
            :index='props.index'
          />
          <MessageCustomEmoji
            v-if="props.message.type == 'TIMCustomElem' && _data.action == 'IMChatCustomEmoji'"
            :isMine="isMine"
            :message="_data"
          />
          <!--     :message="filterFn(sensitiveWords, props.message.payload.text)" -->
          <MsgText
            :isMine="isMine"
            :message="props.message.payload.text"
            v-if="props.message.type == 'TIMTextElem'"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup name="MessageItem">
import GroupTipsCustom from "./groupCustomTips.vue";
import RedPacketTips from "./RedPacketTips.vue";
import { chat } from "@/untils/tim";
import TencentCloudChat from "@tencentcloud/chat";
import { computed, onMounted, ref, watchEffect } from "vue";
import MsgText from "./messageText.vue";
import MsgVideo from "./msgVideo.vue";
import MessageImg from "./messageImg.vue";
import MessageRedEnvelope from "./messageRedEnvelope.vue";
import MessageCustomEmoji from "./messageCustomEmoji.vue";
import GroupTip from "./groupTip.vue";
import Bus from "../../../untils/event-bus";
import { useStore } from "vuex";
// import { filterFn } from "@/untils/index";
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const sensitiveWords = computed(() => store.state.imJs.sensitiveWords);
const userInfo = computed(() => store.state.userJs.userInfo);
const _data = ref();
const store = useStore();
const imChatType = ref("");
const props = defineProps({
  message: {
    type: Object,
    default: {},
  },
  index: {
    type: Number,
    default: 0,
  },
});
const isMine = computed(() => props.message.flow === "out");
const messagePosition = computed(() =>
  props.message.flow === "out" ? "position-right" : "position-left"
);
function gotouchstart() {
  // var that = this;
  // clearTimeout(that.timeOut);
  // that.timeOut = setTimeout(function () {
  //   // that.$store.dispatch("updateSendMsg", "@阿卡纳");
  //   Bus.$emit("updateSendMsg", { msg: "@阿卡纳 " });
  // }, 1000);
}

onMounted(() => {
  imChatType.value = localStorage.getItem("imChatType");
});
watchEffect(() => {
  if (props.message.type == "TIMCustomElem") {
    _data.value = JSON.parse(props.message.payload.data);

    if (_data.value.action == "IMChatRedPocket" && props.message.isSupportExtension) {
      chat()
        .getMessageExtensions(props.message)
        .then((res) => {
          //console.log(res);
          const { extensions } = res.data;
          if (localStorage.getItem("imChatType") == "GROUP") {
            extensions.forEach((item) => {
              if (item.value == userInfo.value.uid + "") {
                props.message.receiveRedPacket = true;
                return;
              }
            });
          } else if (localStorage.getItem("imChatType") == "C2C" && extensions.length > 0) {
            props.message.receiveRedPacket = true;
          } else if (localStorage.getItem("imChatType") == "C2C" && extensions.length == 0) {
            props.message.receiveRedPacket = false;
          }
          //console.log(props.message);
        });
    }
  }
});
</script>
<style scoped>
.message-wrapper {
  padding: 0 3.2vw;
  margin: 2.66vw 0 3.2vw;
}
.item {
  display: flex;
  align-items: flex-start;
}
.col-1 img {
  width: 10.66vw;
  height: 10.66vw;
  border-radius: 50%;
  object-fit: cover;
}

.col_2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.person_name {
  color: #9ca3af;
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-weight: 400;
  line-height: 3.2vw; /* 100% */
  margin-left: 2.66vw;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.person_name img {
  width: 10.13vw;
  height: auto;
  margin-left: 6px;
}

.position-right {
  flex-direction: row-reverse;
}
.position-left .col-2 {
  justify-content: space-around;
}
.position-right .col-2 {
  align-items: flex-end;
}
.date {
  color: #9ca3af;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
}
.touch {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
