<template>
  <div class="contaier" @scroll="onScroll" ref="messageListDom">
    <LoadingCom v-if="loading" />
    <div v-else>
      <div class="more_text" v-if="!isCompleted" @click="_getMessageList">查看更多消息</div>
      <MessageItem v-for="(message, l) in list" :key="l" :message="message" :index="l" />
    </div>
  </div>
</template>
<script setup name="messageContainer">
import MessageItem from "./messageItem.vue";
import { ref, onMounted, nextTick, onBeforeUnmount } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";
import Bus from "@/untils/event-bus";
import { useRouter } from "vue-router";
import { chat } from "@/untils/tim";
import { showToast } from "vant";
import TencentCloudChat from "@tencentcloud/chat";
const userInfo = computed(() => store.state.userJs.userInfo);

const list = ref([]);
const store = useStore();
const preScrollHeight = ref(0);
const messageListDom = ref();
const isCompleted = ref(true); //false  表示没拉完消息
const nextReqMessageID = ref();
const imChatType = ref("");
const imChatId = ref("");
const loading = ref(true);
const first = ref(true);
const emit = defineEmits(["getAllProhibitioValue", "getUserProhibitioValue"]);
const router = useRouter();

function onScroll({ target: { scrollTop } }) {
  let messageListNode = messageListDom.value;
  if (!messageListNode) {
    return;
  }
  preScrollHeight.value = messageListNode.scrollHeight;
  console.log(messageListNode.scrollTop);
  if (scrollTop < 50 && !isCompleted.value) {
    first.value = false;
    _getMessageList();
  }
}

function _getMessageList() {
  imChatType.value = localStorage.getItem("imChatType");
  imChatId.value = localStorage.getItem("imChatId");

  chat()
    .getMessageList({
      conversationID: imChatType.value + imChatId.value,
      nextReqMessageID: nextReqMessageID.value,
    })
    .then((imResponse) => {
      loading.value = false;
      const messageList = imResponse.data.messageList; // 消息列表。
      list.value = messageList.concat(list.value);
      isCompleted.value = imResponse.data.isCompleted;
      nextReqMessageID.value = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
      console.log(imResponse);
    })
    .then(() => {
      if (messageListDom.value && first.value) {
        messageListDom.value.scrollTop = messageListDom.value.scrollHeight;
      }
    });
}

function onMessageReceived(event) {
  console.log(event.data);
  // 有新的消息进来
  let imChatType = localStorage.getItem("imChatType");
  let imChatId = localStorage.getItem("imChatId");
  let _conversationID = imChatType + imChatId;
  let arr = event.data.filter((item) => item.conversationID == _conversationID);

  list.value = list.value.concat(arr);
  //console.log(messageListDom.value);
  nextTick(() => {
    if (messageListDom.value) {
      messageListDom.value.scrollTop = messageListDom.value.scrollHeight;
    }
  });

  chat()
    .setMessageRead({
      conversationID: _conversationID,
    })
    .then((res) => {});
  event.data.forEach((element) => {
    // console.log(element);
    if (element.type == "TIMGroupSystemNoticeElem" && element.payload.operationType == 16) {
      if (element.payload.groupProfile.to == userInfo.value.uid && element.payload.muteTime == 0) {
        emit("getUserProhibitioValue", false);
      }
    }
    if (element.type == "TIMGroupSystemNoticeElem" && element.payload.operationType == 4) {
      if (element.payload.groupProfile.groupID == imChatId) {
        showToast("您被移出该群聊！");
        router.push({
          name: "Msg",
        });

        chat().deleteConversation("GROUP" + element.payload.groupProfile.groupID); //删除被踢出群聊的会话
      }
    }
    if (element.type == "TIMGroupSystemNoticeElem" && element.payload.operationType == 5) {
      if (element.payload.groupProfile.groupID == imChatId) {
        showToast("该群聊已经被解散！");
        router.push({
          name: "Msg",
        });
      }
      if (element.payload.operatorID != userInfo.value.uid) {
        chat().deleteConversation("GROUP" + element.payload.groupProfile.groupID); //删除被踢出群聊的会话
      }
      //chat().deleteConversation("GROUP" + element.payload.groupProfile.groupID); //删除被踢出群聊的会话
    }
    if (element.type == "TIMGroupTipElem" && element.payload.operationType == 6) {
      if (element.payload.newGroupProfile.muteAllMembers) {
        nextTick(() => {
          emit("getAllProhibitioValue", true);
        });
      } else {
        nextTick(() => {
          emit("getAllProhibitioValue", false);
        });
      }
    }

    if (element.type == "TIMGroupTipElem" && element.payload.operationType == 7) {
      element.payload.memberList.forEach((item) => {
        if (item.userID == userInfo.value.uid && item.muteTime > 0) {
          nextTick(() => {
            emit("getUserProhibitioValue", true);
          });
        }
        if (item.userID == userInfo.value.uid && item.muteTime == 0) {
          nextTick(() => {
            emit("getUserProhibitioValue", false);
          });
        }
      });
    }
  });
}
function onMessageRevoked(event) {
  const middleData = list.value;
  list.value = [];
  nextTick(() => {
    list.value = middleData;
  });
}
onMounted(() => {
  Bus.$on("sendImMsg", (arr) => {
    nextTick(() => {
      list.value = list.value.concat(arr);
      setTimeout(function () {
        if (messageListDom.value) {
          messageListDom.value.scrollTop = messageListDom.value.scrollHeight;
        }
      }, 10);
    });
  });
  chat().on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, onMessageReceived);
  // 收到消息被撤回的通知
  chat().on(TencentCloudChat.EVENT.MESSAGE_REVOKED, onMessageRevoked);
  if (localStorage.getItem("imChatType") && localStorage.getItem("imChatId")) {
    setTimeout(function () {
      _getMessageList();
    }, 1000);
  } else {
    router.push({
      name: "Msg",
    });
  }
});
onBeforeUnmount(() => {
  console.log("取消消息监听");
  chat().off(TencentCloudChat.EVENT.MESSAGE_RECEIVED, onMessageReceived);
  chat().off(TencentCloudChat.EVENT.MESSAGE_REVOKED, onMessageRevoked);
});
</script>

<style scoped>
.contaier {
  height: 100%;
  overflow-y: scroll;
}
.van-loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contaier::-webkit-scrollbar {
  display: none;
}
.more_text {
  text-align: center;
  cursor: pointer;
  color: #409eff;
  padding: 4px 0;
  font-size: 12px;
}
</style>
